/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Edge = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var navUISearch = new UISearch( document.getElementById( 'sb-search' ) );

        $('.video-previews').lightGallery({
            download: false,
        });

        $('#main-navbar-collapse').on('show.bs.collapse', function(e) {
            navUISearch.open();
        });

        $(document).on('change', '.btn-file :file', function() {
            var input = $(this),
                numFiles = input.get(0).files ? input.get(0).files.length : 1,
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            input.trigger('fileselect', [numFiles, label]);
        });

        $(document).ready( function() {
            $('.btn-file :file').on('fileselect', function(event, numFiles, label) {

                var input = $(this).parents('.input-group').find(':text'),
                    log = numFiles > 1 ? numFiles + ' files selected' : label;

                if( input.length ) {
                    input.val(log);
                } else {
                    if( log ) {
                        alert(log);
                    }
                }

            });
        });

        $('.popup-link').magnificPopup({
            closeBtnInside: true
        });

        (function(){
            "use strict";

            var root = this,
                previous = root.Meter;

            var Meter = function(element){
                var self = this;

                this.formatMoney = function(value){
                    var currencySymbol = this.currencySymbol;
                    var decimalSymbol = this.decimalSymbol;
                    var numberSeparator = this.numberSeparator;
                    var formatString = '';

                    var decimalPrecisionHolders = '';

                    for (var i = 0; i < parseInt(this.decimalPlaces); i++) {
                        decimalPrecisionHolders = decimalPrecisionHolders + '0';
                    }

                    if (numberSeparator.toLowerCase() === 'none') {
                        numberSeparator = '';
                    }

                    switch (this.currencySymbolLocation) {
                        default:
                        case 'front':
                            formatString = currencySymbol + '0' + numberSeparator + '0' + decimalSymbol + decimalPrecisionHolders;
                            break;

                        case 'back':
                            formatString = '0' + numberSeparator + '0' + decimalSymbol + decimalPrecisionHolders + currencySymbol;
                            break;
                    }

                    return numeral(value).format(formatString);
                };


                this.defaults = {
                    activeView: 'percent',

                    segments: 30,

                    initialFundGoal: 0,

                    initialFundCurrent: 0,

                    filledSegmentColor: '#FF0000',

                    filledSegmentHighlight: '#FF0000',

                    emptySegmentColor: '#00FF00',

                    emptySegmentHighlight: '#00FF00',

                    segmentStrokeColor: '#0000FF',

                    numberSeparator: 'none',

                    decimalPlaces: 0,

                    decimalSymbol: '.',

                    currencySymbol: '$',

                    currencySymbolLocation: 'front',

                    hideProgressBarGoal: false,

                    hideProgressBarValue: false,

                    hideProgressBarAnnotation: false,

                    progressBarAnnotationStart: 'On Our Way',

                    progressBarAnnotationInProgress: 'Almost There',

                    progressBarAnnotationGoalReached: 'Goal Reached',

                    progressBarAnnotationChangePercent: 25,
                };

                this.$element = $(element);
                this.element = this.$element[0];

                this.activeView = this.defaults.activeView;
                this.segments = this.defaults.segments;
                this.fundGoal = this.defaults.fundGoal;
                this.fundCurrent = this.defaults.fundCurrent;
                this.filledSegmentColor = this.defaults.filledSegmentColor;
                this.filledSegmentHighlight = this.defaults.filledSegmentHighlight;
                this.emptySegmentColor = this.defaults.emptySegmentColor;
                this.emptySegmentHighlight = this.defaults.emptySegmentHighlight;
                this.segmentStrokeColor = this.defaults.segmentStrokeColor;
                this.numberSeparator = this.defaults.numberSeparator;
                this.decimalPlaces = this.defaults.decimalPlaces;
                this.decimalSymbol = this.defaults.decimalSymbol;
                this.currencySymbol = this.defaults.currencySymbol;
                this.currencySymbolLocation = this.defaults.currencySymbolLocation;
                this.hideProgressBarGoal = this.defaults.hideProgressBarGoal;
                this.hideProgressBarValue = this.defaults.hideProgressBarValue;
                this.hideProgressBarAnnotation = this.defaults.hideProgressBarAnnotation;
                this.progressBarAnnotationStart = this.defaults.progressBarAnnotationStart;
                this.progressBarAnnotationInProgress = this.defaults.progressBarAnnotationInProgress;
                this.progressBarAnnotationGoalReached = this.defaults.progressBarAnnotationGoalReached;
                this.progressBarAnnotationChangePercent = this.defaults.progressBarAnnotationChangePercent;

                var elementActiveView = this.$element.data('active-view');
                if (typeof elementActiveView !== 'undefined'){
                    this.activeView = elementActiveView;
                }

                var elementFundGoal = this.$element.data('fund-goal');
                if (typeof elementFundGoal !== 'undefined'){
                    this.fundGoal = elementFundGoal;
                }

                var elementFundCurrent = this.$element.data('fund-current');
                if (typeof elementFundCurrent !== 'undefined'){
                    this.fundCurrent = elementFundCurrent;
                }

                var elementFilledSegmentColor = this.$element.data('filled-segment-color');
                if (typeof elementFilledSegmentColor !== 'undefined'){
                    this.filledSegmentColor = elementFilledSegmentColor;
                }

                var elementFilledSegmentHighlight = this.$element.data('filled-segment-highlight');
                if (typeof elementFilledSegmentHighlight !== 'undefined'){
                    this.filledSegmentHighlight = elementFilledSegmentHighlight;
                }

                var elementEmptySegmentColor = this.$element.data('empty-segment-color');
                if (typeof elementEmptySegmentColor !== 'undefined'){
                    this.emptySegmentColor = elementEmptySegmentColor;
                }

                var elementEmptySegmentHighlight = this.$element.data('empty-segment-highlight');
                if (typeof elementEmptySegmentHighlight !== 'undefined'){
                    this.emptySegmentHighlight = elementEmptySegmentHighlight;
                }

                var elementSegmentStrokeColor = this.$element.data('segment-stroke-color');
                if (typeof elementSegmentStrokeColor !== 'undefined'){
                    this.segmentStrokeColor = elementSegmentStrokeColor;
                }

                var elementNumberSeparator = this.$element.data('number-separator');
                if (typeof elementNumberSeparator !== 'undefined'){
                    this.numberSeparator = elementNumberSeparator;
                }

                var elementDecimalPlaces = this.$element.data('decimal-places');
                if (typeof elementDecimalPlaces !== 'undefined'){
                    this.decimalPlaces = elementDecimalPlaces;
                }

                var elementDecimalSymbol = this.$element.data('decimal-symbol');
                if (typeof elementDecimalSymbol !== 'undefined'){
                    this.decimalSymbol = elementDecimalSymbol;
                }

                var elementCurrencySymbol = this.$element.data('currency-symbol');
                if (typeof elementCurrencySymbol !== 'undefined'){
                    this.currencySymbol = elementCurrencySymbol;
                }

                var elementCurrencySymbolLocation = this.$element.data('currency-symbol-location');
                if (typeof elementCurrencySymbolLocation !== 'undefined'){
                    this.currencySymbolLocation = elementCurrencySymbolLocation;
                }

                var elementHideProgressBarGoal = this.$element.data('hide-progress-bar-goal');
                if (typeof elementHideProgressBarGoal !== 'undefined'){
                    this.hideProgressBarGoal = elementHideProgressBarGoal;
                }

                var elementHideProgressBarValue = this.$element.data('hide-progress-bar-value');
                if (typeof elementHideProgressBarValue !== 'undefined'){
                    this.hideProgressBarValue = elementHideProgressBarValue;
                }

                var elementHideProgressBarAnnotation = this.$element.data('hide-progress-bar-annotation');
                if (typeof elementHideProgressBarAnnotation !== 'undefined'){
                    this.hideProgressBarAnnotation = elementHideProgressBarAnnotation;
                }

                var elementProgressBarAnnotationStart = this.$element.data('progress-bar-annotation-start');
                if (typeof elementProgressBarAnnotationStart !== 'undefined'){
                    this.progressBarAnnotationStart = elementProgressBarAnnotationStart;
                }

                var elementProgressBarAnnotationInProgress = this.$element.data('progress-bar-annotation-in-progress');
                if (typeof elementProgressBarAnnotationInProgress !== 'undefined'){
                    this.progressBarAnnotationInProgress = elementProgressBarAnnotationInProgress;
                }

                var elementProgressBarAnnotationGoalReached = this.$element.data('progress-bar-annotation-goal-reached');
                if (typeof elementProgressBarAnnotationGoalReached !== 'undefined'){
                    this.progressBarAnnotationGoalReached = elementProgressBarAnnotationGoalReached;
                }

                var elementProgressBarAnnotationChangePercent = this.$element.data('progress-bar-annotation-change-percent');
                if (typeof elementProgressBarAnnotationChangePercent !== 'undefined'){
                    this.progressBarAnnotationChangePercent = elementProgressBarAnnotationChangePercent;
                }

                this.$overlay = $('<div class="meter-overlay"></div>');
                this.overlay = this.$overlay[0];

                this.$canvas = $('<canvas class="meter-canvas"></canvas>');
                this.canvas = this.$canvas[0];

                this.$text = $('<div class="meter-text"></div>');
                this.text = this.$text[0];

                this.$meterToggle = $('<ul class="meter-toggle list-unstyled">');
                this.meterToggle = this.$meterToggle[0];

                this.$moneyToggleButton = $('<li><a href="#">$</a></li>');
                this.moneyToggleButton = this.$moneyToggleButton[0];

                this.$percentToggleButton = $('<li><a href="#" class="active last">%</a></li>');
                this.percentToggleButton = this.$percentToggleButton[0];

                this.$moneyContainer = $('<div class="meter-money-container"></div>');
                this.moneyContainer = this.$moneyContainer[0];

                this.$moneyProgressBarContainer = $('<div class="meter-money-progress-bar-container"></div>');
                this.moneyProgressBarContainer = this.$moneyProgressBarContainer[0];

                this.$moneyProgressBar = $('<div class="meter-money-progress-bar"></div>');
                this.moneyProgressBar = this.$moneyProgressBar[0];

                this.$moneyLowerBoundText = $('<div class="meter-money-text meter-money-lower-bound-text">' + this.formatMoney(0) + '</div>');
                this.moneyLowerBoundText = this.$moneyLowerBoundText[0];

                this.$moneyUpperBoundText = $('<div class="meter-money-text meter-money-upper-bound-text">' + this.formatMoney(this.fundGoal) + '</div>');
                this.moneyUpperBoundText = this.$moneyUpperBoundText[0];

                this.$moneyCurrentText = $('<div class="meter-money-text meter-money-current-text">' + this.formatMoney(this.fundCurrent) + '</div>');
                this.moneyCurrentText = this.$moneyCurrentText[0];

                this.$moneyAnnotation = $('<div class="meter-money-text meter-money-annotation">Almost There</div>');
                this.moneyAnnotation = this.$moneyAnnotation[0];

                this.$percentContainer = $('<div class="meter-percent-container"></div>');
                this.percentContainer = this.$percentContainer[0];

                this.initialize = function(){
                    if (this.$element.length) {
                        this.$element.append(this.$overlay);
                        this.$overlay.append(this.$moneyContainer);
                        this.$moneyContainer.append(this.$moneyProgressBarContainer);
                        this.$moneyProgressBarContainer.append(this.$moneyProgressBar);
                        this.$moneyContainer.append(this.$moneyLowerBoundText);
                        this.$moneyContainer.append(this.$moneyUpperBoundText);
                        this.$moneyContainer.append(this.$moneyCurrentText);
                        this.$moneyContainer.append(this.$moneyAnnotation);
                        this.$overlay.append(this.$percentContainer);
                        this.$percentContainer.append(this.$canvas);
                        this.$percentContainer.append(this.$text);
                        this.$overlay.append(this.$meterToggle);
                        this.$meterToggle.append(this.$moneyToggleButton);
                        this.$meterToggle.append(this.$percentToggleButton);

                        this.$percentToggleButton.on('click', this.toggleView);
                        this.$moneyToggleButton.on('click', this.toggleView);

                        this.ctx = this.canvas.getContext('2d');

                        this.showActiveView();

                        this.draw();

                        $(window).resize(function(){
                            self.resize();
                        });
                    }
                };

                this.showActiveView = function(){
                    switch (this.activeView) {
                        case 'money':
                            this.$percentContainer.hide();
                            this.$moneyContainer.show();
                            break;

                        case 'percent':
                            this.$moneyContainer.hide();
                            this.$percentContainer.show();
                            break;
                    }
                };

                this.draw = function(){
                    this.redraw();
                };

                this.redraw = function(){
                    this.$text.text(Math.round(100 * this.getPercent()) + '%');

                    this.sizeCanvas();
                    this.sizeMoneyProgressBar();

                    if (typeof this.chart === 'undefined'){
                        this.chart = new Chart(this.ctx).Doughnut(this.getSegments(), {
                            segmentShowStroke : true,
                            segmentStrokeColor : this.segmentStrokeColor,
                            segmentStrokeWidth : 3,
                            percentageInnerCutout : 85,
                            showTooltips: false
                        });
                    } else {
                        this.chart.update();
                    }
                };

                this.resize = function(){
                    this.redraw();
                };

                this.sizeCanvas = function(){
                    if (this.$element.width() > this.$element.height()) {
                        this.$canvas.width(this.$element.height() * 0.65);
                        this.$canvas.height(this.$element.height() * 0.65);
                        this.$percentContainer.width(this.$element.height() * 0.65);
                    } else {
                        this.$canvas.width(this.$element.width() * 0.65);
                        this.$canvas.height(this.$element.width() * 0.65);
                        this.$percentContainer.width(this.$element.width() * 0.65);
                    }
                    this.$canvas.css('top', ((this.$element.height() - this.$text.height()) / 2) + 'px');

                    this.$overlay.css('padding-top', ((this.$element.height() - this.$canvas.height()) / 2) + 'px');

                    this.$text.css('top', ((this.$element.height() - this.$text.height()) / 2) + 'px');
                    this.$text.css('left', ((this.$element.width() - this.$text.width()) / 2 + 5) + 'px');
                };

                this.sizeMoneyProgressBar = function(){
                    this.$moneyProgressBarContainer.height(this.$element.height() * 0.65);
                    this.$moneyProgressBarContainer.css('top', ((this.$element.height() - this.$moneyProgressBarContainer.height()) / 2 + 25) + 'px');
                    this.$moneyProgressBarContainer.css('left', ((this.$element.width() - this.$moneyProgressBarContainer.width()) / 2) + 'px');

                    this.$moneyProgressBar.height((this.getPercent() * 100) + '%');

                    var position = parseInt(this.$moneyProgressBarContainer.css('top'));
                    this.$moneyLowerBoundText.css('top', (position + this.$moneyProgressBarContainer.height() + 5) + 'px');
                    this.$moneyUpperBoundText.css('top', (position - this.$moneyUpperBoundText.height() - 5) + 'px');

                    position = parseInt(this.$moneyUpperBoundText.css('top'));
                    this.$moneyAnnotation.css('top', (position - this.$moneyAnnotation.height() - 5) + 'px');

                    if (this.getPercent() > 0.99) {
                        this.$moneyAnnotation.text(this.progressBarAnnotationGoalReached);
                    } else if (this.getPercent() > (parseInt(this.progressBarAnnotationChangePercent) / 100)) {
                        this.$moneyAnnotation.text(this.progressBarAnnotationInProgress);
                    } else {
                        this.$moneyAnnotation.text(this.progressBarAnnotationStart);
                    }

                    position = parseInt(this.$moneyProgressBarContainer.css('left'));
                    this.$moneyCurrentText.css('left', (position + this.$moneyProgressBarContainer.width() + 5) + 'px');

                    position = parseInt(this.$moneyProgressBarContainer.css('top'));
                    this.$moneyCurrentText.css('top', (position + this.$moneyProgressBarContainer.height() - this.$moneyProgressBar.height()) + 'px');

                    if (this.hideProgressBarGoal === true) {
                        this.$moneyUpperBoundText.hide();
                        this.$moneyLowerBoundText.hide();
                    } else {
                        this.$moneyUpperBoundText.show();
                        this.$moneyLowerBoundText.show();
                    }

                    if (this.hideProgressBarValue === true) {
                        this.$moneyCurrentText.hide();
                    } else {
                        this.$moneyCurrentText.show();
                    }

                    if (this.hideProgressBarAnnotation === true) {
                        this.$moneyAnnotation.hide();
                    } else {
                        this.$moneyAnnotation.show();
                    }
                };

                this.toggleView = function(ev){
                    ev.preventDefault();

                    self.$moneyToggleButton.find('a').removeClass('active');
                    self.$percentToggleButton.find('a').removeClass('active');

                    switch (self.activeView) {
                        case 'percent':
                            self.$percentContainer.fadeOut();
                            self.$moneyContainer.fadeIn();
                            self.activeView = 'money';
                            self.$moneyToggleButton.find('a').addClass('active');
                            break;

                        case 'money':
                            self.$moneyContainer.fadeOut();
                            self.$percentContainer.fadeIn();
                            self.activeView = 'percent';
                            self.$percentToggleButton.find('a').addClass('active');
                            break;
                    }

                    self.redraw();
                };

                this.getSegments = function(){
                    var segments = [];

                    var filledSegmentCount = Math.round(this.getPercent() * this.segments);

                    for (var i = 0; i < filledSegmentCount; i++){
                        segments[segments.length] = {
                            value: 1,
                            color: this.filledSegmentColor,
                            highlight: this.filledSegmentHighlight
                        };
                    }

                    for (i = filledSegmentCount; i < this.segments; i++){
                        segments[segments.length] = {
                            value: 1,
                            color: this.emptySegmentColor,
                            highlight: this.emptySegmentHighlight
                        };
                    }

                    return segments;
                };

                this.getPercent = function() {
                    var percent = 0;

                    if (this.fundGoal > 0) {
                        percent = this.fundCurrent / this.fundGoal;
                    }

                    if (percent < 0) {
                        percent = 0;
                    }

                    if (percent > 1) {
                        percent = 1;
                    }

                    return percent;
                };

                this.initialize.call(this);
            };

            var meter = new Meter($('.meter'));

        }).call(this);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Team Home page
    'team_home': {
      init: function() {
        // JavaScript to be fired on the home page
        (function($){
            $('form.team-search .hide-list-button').on('click', function(ev){
                ev.preventDefault();

                if ($(this).hasClass('hiding')) {
                    $(this).text('- HIDE LIST');
                    $(this).removeClass('hiding');
                } else {
                    $(this).text('- SHOW LIST');
                    $(this).addClass('hiding');
                }

                $(this).closest('form.team-search').find('.search-form-item').each(function() {
                    if ($(this).hasClass('hiding')) {
                        $(this).fadeIn();
                        $(this).removeClass('hiding');
                    } else {
                        $(this).fadeOut();
                        $(this).addClass('hiding');
                    }
                });
            });

            $('form.team-search').find('#searchField').on('keyup', function(){
                var value = $(this).val();

                $(this).closest('form.team-search').find('.team-members .team-member').each(function() {
                    if ($(this).data('name').toLowerCase().indexOf(value) >= 0) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            });
        })(jQuery);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Edge;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
